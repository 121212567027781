import { css } from '@emotion/react';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { gray7 } from 'styles/global_defaults/colors';
import { screenMdMax, screenXsMax } from 'styles/global_defaults/media-queries';
import { lectureLeftPanelZIndex, tripleSpacing } from 'styles/global_defaults/scaffolding';
import AddComponentTopLevel from './add-component-top-level';
import AddContentComponent from './add-content-component';
import CourseOutlineNavigation from './course-outline-navigation';
import LeftPanelPlaceHolder from './left-panel-placeholder';
import CollectionOutlineNavigation from './collection-outline-navigation';
import CourseOutlineNavigationNotOptimized from './course-outline-navigation-not-optimized';
import { LecturePageMode } from '..';
import NovoAiFlyout from './novo-ai/flyout';

type LecturePageLeftPanelProps = {
  // When true, this panel will float above the page content with absolute positioning at a fixed width
  floatAbove: boolean,
};

/** The left-side panel (in left-to-right languages) that contains UIs for navigating through a course via the outline
 * and for adding new lecture components */
export const LecturePageLeftPanel = (props: LecturePageLeftPanelProps) => {
  // Determines which specific UI is displayed in this panel. This is updated via the Redux app state in response to URL changes, button
  // clicks, etc
  const leftPanelView = useSelector(state => state.app.lecturePage.leftPanelView);
  const isTimelineContentLoaded = useSelector(state => state.app.lecturePage.isTimelineContentLoaded);

  const isMobile = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });
  const isSmallDesktop = useMediaQuery({
    query: `(max-width: ${screenMdMax}px)`,
  });

  const styles = css`
    display: flex;
    justify-content: center;
    z-index: ${lectureLeftPanelZIndex};

    // AI items need 280px min width so u
    width: ${(isSmallDesktop && leftPanelView === 'novo_ai') ? '320px' : '25%'};
    height: 100%;
    flex-shrink: 0;
    background-color: ${gray7};
    overflow-y: auto;

    ${props.floatAbove && css`
      position: absolute;
      width: ${isMobile ? '100%' : '320px'};
    `}

    /** Large padding offset applied to ensure outline is placed north of the bottom bar */
    padding-bottom: 100px;
    & > * {
      height: max-content;
    }

    ${leftPanelView === 'novo_ai' && css`
      background: linear-gradient(315deg, rgba(220, 253, 253, 0.30) 0%, rgba(247, 251, 232, 0.30) 99.28%), #FFF;
      padding-bottom: ${tripleSpacing}px;
    `}
  `;

  const ref = useRef(null);
  const params = useLecturePageParams();
  const { $timeout } = useContext(AngularServicesContext);

  /**
   * Scrolling to the top is not working as expected when switching LHS view to
   * add while scrolling initiated on the existing view. Using `scrollToTop`
   * will force scroll to the top of the page when the user clicks on the add
   * component button.
   */
  const scrollToTop = useRef(false);
  /**
   * Scrolling to the current lecture page LHS item. Retrying the scroll if the
   * lecture item is not found in the dom.
   * `scrollIntoView` doesn't scroll as expected here. So implemented the old
   * lecture timeline scroll logic here.
   */
  const tryScroll = useCallback((tries: number) => {
    if (tries < 0 || !ref.current) {
      return;
    }

    const scrollPosition = $(`#timeline-lecture-page-${params.lecturePageId}`).position();
    const $timelinePanel = $(ref.current);
    const initialScrollHeight = ref.current?.scrollHeight;

    if (scrollPosition) {
      tries = 0;
      $timelinePanel.animate(
        { scrollTop: scrollPosition.top + $timelinePanel.scrollTop() },
        'slow',
        () => {
          if (scrollToTop.current) {
            ref.current?.scroll({ top: 0, left: 0, behavior: 'smooth' });
            scrollToTop.current = false;
          }
        },
      );
      /**
       * Subsection expansion toggling sometimes occurs after scrolling. This will
       * determine whether the panel has been toggled by using its scrollHeight,
       * and will re-call the tryScroll if the height has changed.
       */
      setTimeout(() => {
        if (initialScrollHeight !== ref.current?.scrollHeight) {
          tryScroll(1);
        }
      });
    } else {
      $timeout(() => {
        tryScroll(tries - 1);
      }, 500);
    }
  }, [$timeout, params.lecturePageId]);

  useEffect(() => {
    if (ref.current && leftPanelView === 'outline') {
      tryScroll(5);
    }
  }, [leftPanelView, tryScroll, isTimelineContentLoaded]);

  useEffect(() => {
    scrollToTop.current = false;

    // Scrolling the LHS list to top when adding a new component.
    if (leftPanelView !== 'outline') {
      scrollToTop.current = true;
      ref.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [leftPanelView]);

  const OutlineNavigation = (params.mode === LecturePageMode.VIEW)
    ? CourseOutlineNavigation
    : CourseOutlineNavigationNotOptimized;

  return (
    <div css={styles} ref={ref} id='lecture-page-left-panel'>
      {(leftPanelView === 'outline')
      && (params.currentCourseIsCollection
        ? <CollectionOutlineNavigation catalogId={params.catalogId} />
        : (
          <React.Fragment>
            {/* The full (not lite) timeline is needed because we don't have enough info to display completion
          statuses in the lite version  in case of course outline */}
            {isTimelineContentLoaded
              ? <OutlineNavigation isCourseHome={false} />
              : <LeftPanelPlaceHolder />}
          </React.Fragment>
        )
      )}

      {leftPanelView === 'add_top_level' && (
        <AddComponentTopLevel />
      )}

      {leftPanelView === 'add_content' && (
        <AddContentComponent />
      )}
      {leftPanelView === 'novo_ai' && (
        <NovoAiFlyout />
      )}
    </div>
  );
};

export default LecturePageLeftPanel;


