import { CourseOutline } from 'redux/selectors/course-outline/use-course-outline';
import { SectionOutlineItem, SubsectionOutlineItem } from '../../redux/selectors/course-outline/course-outline-item';

export type Section = SectionOutlineItem | SubsectionOutlineItem;

/**
 * Transforms the original course outline data into a structured format where sections and subsections contain their respective lectures. This transformation is intended to support the creation of an accessible HTML structure, making it easier to correctly associate child items (lectures) with their parent items (sections or subsections).
 * @param data - An array of objects representing the course outline.
 *               Each object can be a section, subsection, or lecture.
 *
 * @returns An array of `Section` objects, where each section or subsection
 *          includes its nested lectures.
 */
export const transformCourseOutline = (data: CourseOutline, isCourseAdmin: boolean): Section[] => {
  const sectionsMap: { [key: number]: Section } = {};

  data.forEach(item => {
    if (item.type === 'section' || item.type === 'subsection') {
      // Initialize a new section or subsection
      sectionsMap[item.id] = {
        ...item,
        skip: !isCourseAdmin && !item.lecturesAreReleased,
        lectures: [],
      };
    } else if (item.type === 'lecture') {
      // Find the parent section or subsection and add the lecture
      const parentSection = sectionsMap[item.section];
      if (parentSection) {
        parentSection.lectures.push({
          ...item,
        });
      }
    }
  });

  return Object.values(sectionsMap);
};

export default transformCourseOutline;
